import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faApple, faGoogle } from "@fortawesome/free-brands-svg-icons"
import TextLoop from "react-text-loop"
import Layout from "../components/layout"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      focus: file(relativePath: { eq: "Focus.png" }) {
        childImageSharp {
          fluid(maxWidth: 233) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tasks: file(relativePath: { eq: "Tasks.png" }) {
        childImageSharp {
          fluid(maxWidth: 167) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      statistics: file(relativePath: { eq: "Statistics.png" }) {
        childImageSharp {
          fluid(maxWidth: 167) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      og: file(relativePath: { eq: "OG.png" }) {
        childImageSharp {
          fixed(width: 1200, height: 627) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout coloredHeader={false}>
      <Helmet>
        <title>Focal - Tasks in Focus</title>
        <meta
          name="description"
          property="og:description"
          content="Join hundreds of satisfied users and use Focal to help you get things done, one task at a time."
        />
        <meta
          name="title"
          property="og:title"
          content="Focal - Tasks in Focus"
        />
        <meta
          name="image"
          property="og:image"
          content={"https://getfocal.app" + data.og.childImageSharp.fixed.src}
        />
        <meta name="apple-itunes-app" content="app-id=1526256598" />
      </Helmet>
      <div className="background"></div>
      <div className="card"></div>
      <div className="banner container">
        <div className="column">
          <TextLoop className="title">
            <div>
              <span className="first">Relentless </span>
              <span className="second">Focus</span>
            </div>
            <div>
              <span className="first">Advanced </span>
              <span className="second">Statistics</span>
            </div>
            <div>
              <span className="first">Instantaneous </span>
              <span className="second">Workflow</span>
            </div>
            <div>
              <span className="first">Smart </span>
              <span className="second">Motivation</span>
            </div>
          </TextLoop>
          <div className="subtitle">
            Focal helps you get things done, one task at a time.
          </div>
          <div className="button-column">
            <a
              href="https://apps.apple.com/us/app/focal-tasks-in-focus/id1526256598"
              className="icon"
            >
              <button className="apple">
                <div className="row">
                  <div className="icon">
                    <FontAwesomeIcon icon={faApple} />
                  </div>
                  <div className="text">App Store</div>
                </div>
              </button>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=technology.focal.focal"
              className="icon"
            >
              <button className="google">
                <div className="row">
                  <div className="icon">
                    <FontAwesomeIcon icon={faGoogle} />
                  </div>
                  <div className="text">Google Play</div>
                </div>
              </button>
            </a>
            <button
              className="download"
              onClick={() => {
                const userAgent =
                  navigator.userAgent || navigator.vendor || window.opera
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                  window.location.href =
                    "https://apps.apple.com/us/app/focal-tasks-in-focus/id1526256598"
                } else if (/android/i.test(userAgent)) {
                  window.location.href =
                    "https://play.google.com/store/apps/details?id=technology.focal.focal"
                }
              }}
            >
              Download
            </button>
          </div>
        </div>
        <div className="images">
          <Img
            fluid={data.statistics.childImageSharp.fluid}
            className="side-image"
            fadeIn={false}
          />
          <Img
            fluid={data.focus.childImageSharp.fluid}
            className="main-image"
            fadeIn={false}
          />
          <Img
            fluid={data.tasks.childImageSharp.fluid}
            className="side-image"
            fadeIn={false}
          />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
